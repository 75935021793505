'use strict'
module.exports = {
  SCREAM_CREAM: "/screamcream",
  HERPES: "/herpes",
  Female_HERPES: "/female-herpes",
  WEIGHTLOSS: "/weightloss",
  Female_WEIGHTLOSS: "/female-weightloss",
  SKIN_CARE: "/skincare",
  Female_SKIN_CARE: "/female-skincare",
  HAIRLOSS: "/hairloss",
  ERECTILE_DYSFUNCTION: "/mensexualproblem",
  COVID_19: "/covid-19",
  Female_COVID_19: "/female-covid-19",
  STD: "/std",
  HORMONE_TEST: "/hormone-test",
  FERTILITY_TEST: "/fertility-test",
  FAQ: "/faq",
  LOGIN: "https://my.getbetterdoctor.com/",
  STARTED_HOME: "https://getbetterdoctor.com/forms/#/select-gender",
  STARTED_FAQ: "https://getbetterdoctor.com/forms/#/select-gender",
  STARTED_ED: "https://getbetterdoctor.com/forms/#/treatments/ed/Male",
  STARTED_HAIRLOSS: "https://getbetterdoctor.com/forms/#/treatments/hair/Male",  
  STARTED_HERPES: "https://getbetterdoctor.com/forms/#/treatments/herpes/Male",
  STARTED_HERPES_FEMALE: "https://getbetterdoctor.com/forms/#/treatments/herpes/Female",
  STARTED_WEIGHTLOSS: "https://getbetterdoctor.com/forms/#/treatments/weight-loss/Male",
  STARTED_WEIGHTLOSS_FEMALE: "https://getbetterdoctor.com/forms/#/treatments/weight-loss/Female",
  STARTED_SKINCARE: "https://getbetterdoctor.com/forms/#/treatments/skin/Male",
  STARTED_SKINCARE_FEMALE: "https://getbetterdoctor.com/forms/#/treatments/skin/Female",
  STARTED_SCREAMCREAM: "https://getbetterdoctor.com/forms/#/treatments/scream-cream/Female",
  STARTED_COVID19: "https://getbetterdoctor.com/forms/#/treatments/covid/Male",
  STARTED_COVID19_FEMALE: "https://getbetterdoctor.com/forms/#/treatments/covid/Female",
//   STARTED_STD: "https://getbetterdoctor.com/forms/#/scream/cream/1",
//   STARTED_HORMONE: "https://getbetterdoctor.com/forms/#/scream/cream/1",
//   STARTED_FERTILITY: "https://getbetterdoctor.com/forms/#/scream/cream/1",
}