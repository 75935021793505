<template>
<div>
    <footer>
        <div class="footer-top">
            <div class="container">
                <div class="row">
                    <div class="col-md-8">
                        <div class="footer-logo">
                            <a href="/"><img src="img/footer-logo.png" class="img-fluid" alt="logo" width="150px" /></a>
                            <br>
                            <a href="http://legitscript.com" target="_blank" title="Verify LegitScript Approval"><img src="https://static.legitscript.com/seals/10869709.png" alt="LegitScript approved" class="pd-7" width="170" height="130" border="0"></a>
                            <a><img src="img/badge1.png" style="padding-left:10px; padding-right:5px" alt="LegitScript approved" class="pd-6" width="140" height="130" border="0"></a>
                            <a><img src="img/badge2.png" class="fold-margin" alt="LegitScript approved" width="140" height="140" border="0"></a>
                            <a><img src="img/badge3.png" class="fold-margin1" alt="LegitScript approved" width="140" height="140" border="0"></a>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="foot-contact foot-common">
                            <h5>contact us</h5>
                            <ul class="list-unstyled">
                                <li class="icon-1">

                                    <a href="https://goo.gl/maps/3k8pkxtZJVqkdCE59">
                                        Better Doctor LLC <br>
                                        2232 Dell Range Blvd <br>
                                        Suite 242 # 5008  <br>
                                        Cheyenne, WY 82009  <br>
                                        United States  <br></a>
                                </li>
                                <li class="icon-2">
                                    <a href="tel:+1 (888)-286-8124">
                                        <span>Phone:</span>
                                        +1 (888)-286-8124
                                    </a>
                                </li>
                                <li class="icon-3 mrgn-top">
                                    <a href="mailto:support@getbetterdoctor.com">
                                        <span>Email:</span>
                                        support@getbetterdoctor.com
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <!-- <div class="col-md-3">
                            <div class="foot-links foot-common">
                                <h5>Quick Links</h5>
                                <ul class="list-unstyled">
                                    <li><router-link to="/">Home</router-link></li>
                                    <li><router-link to="/faq">FAQ</router-link></li>
                                    <li><router-link to="/labtesting">Lab Testing</router-link></li>
                                    <li><router-link to="/treatments">Treatments</router-link></li>
                                </ul>
                            </div>
                        </div> -->
                </div>
            </div>
        </div>
        <div class="footer-bottom">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div class="copyrights" style="display:flex; justify-content:center">
                            <p>Better Doctor LLC &copy; 2023 All Rights Reserved | <router-link to="/privacy-policy">Privacy Policy</router-link> | <router-link to="/terms-and-condition">Terms of Service</router-link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</div>
</template>

<script>
export default {
    name: "Footer",

}
</script>

<style scoped>
@media only screen and (max-width: 280px) {
    .pd-7 {
        margin-left: 0px !important;
        margin-bottom: 10px;
    }

    .fold-margin {
        margin-left: 6px;
    }
}

@media only screen and (min-width: 281px) and (max-width: 320px) {
    .pd-7 {
        margin-left: -25px !important;
        margin-bottom: 10px;
    }

    .fold-margin {
        margin-left: 0px !important;
    }

    .fold-margin1 {
        margin-left: 10px !important;
    }
}

@media only screen and (min-width: 768px) and (max-width: 768px) {
    .fold-margin {
        margin-left: 0px !important;
    }
}

@media only screen and (min-width: 769px) and (max-width: 834px) {
    .fold-margin {
        margin-left: 16px;
    }
}

@media only screen and (min-width: 883px) and (max-width: 884px) {
    .fold-margin {
        margin-left: 16px;
    }
}

@media only screen and (max-width: 768px) {
    .pd-6 {
        padding-left: -8px;
    }

    .pd-7 {
        margin-left: -25px;
    }

}

@media only screen and (min-width: 321px) and (max-width: 500px) {
    .fold-margin1 {
        margin-left: 15px !important;
    }
}
</style>
