<template>
    <div class="home">
        <agile :infinite="loop" :dots="false" :options="myOptions" :mobileFirst="true" :autoplay-speed="6000" :speed="100"
            fade="fade" pause-on-hover="pause-on-hover" pause-on-dots-hover="pause-on-dots-hover" autoplay="autoplay">
            <div class="slide-1">
                <div class="header-bottom home-banner">
                    <div class="banner-wrap banner-color">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="banner-contents" data-aos="fade-up" data-aos-delay="0">
                                        <br>
                                        <br>
                                        <h1 style="color: white">Live a <img style="width:110px" src="img/footer-logo.png">
                                            Life</h1>
                                        <br>
                                        <div class="row">
                                            <div class="col col-lg-10 col-sm-12  ">
                                                <h4 class="mobilePadding" style="color: white">Medicine and Lab Tests <br>
                                                    Prescribed Online <br> Delivered to Your Door</h4>
                                                <h4 class="mobilePadding" style="color: white">No Hassle. No Waiting Rooms.
                                                    <br> No Appointments.
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="banner-contents" data-aos="fade-up" data-aos-delay="0">
                                        <div class="banner-features">
                                            <ul class="list-unstyled d-flex d-inline-flex m-0">
                                                <li class="pixel-2 pixel-3">
                                                    <span>
                                                        <img src="img/icons/feature-1.webp" class="img-fluid" alt="icon" />
                                                    </span>
                                                    U.S. Board Certified <br /> Professionals
                                                </li>
                                                <li class="pixel-2 pixel2 banner-1-icon">
                                                    <span class="button-icon-pd">
                                                        <img src="img/icons/depression-bannericon-1.webp" class="img-fluid"
                                                            alt="icon" />
                                                    </span>
                                                    Secure and <br /> Confidential
                                                </li>
                                                <li class="pixel-2">
                                                    <span>
                                                        <img src="img/icons/treatment-bannericon-3.webp" class="img-fluid"
                                                            alt="icon" />
                                                    </span>
                                                    Avoid Expensive <br /> Doctor Visits
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-3"></div>
                                <div class="col-md-6" style="margin-bottom:15px">
                                    <br><br>
                                    <center><a :href='started_home' class="buttonstyle btn filled-btn labtest-btn hvr-grow">
                                            Get Started </a></center>
                                    <br>
                                </div>
                                <div class="col-md-3"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="slide-2">
                <div class="header-bottom ed-banner2">
                    <div class="banner-wrap banner-color">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-12"
                                    style="color:white; text-align:center; font-weight: 600px !important">
                                    <div class="banner-contents" data-aos="fade-up" data-aos-delay="0">
                                        <br>
                                        <h1 style="color: white">E.D. Fast Acting Lozenges</h1>
                                        <br>
                                        <br>
                                        <br><br>
                                                <h4  class="mobilePadding" style="color: white">No Need to plan </h4>
                                       

                                                <h4  class="mobilePadding" style="color: white">
                                                    No Need to Wait
                                                </h4>
                                     
                                                <h4  class="mobilePadding" style="color: white">Seize the Moment</h4>
                                       


                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-3"><br><br><br></div>
                                <div class="col-md-6" style="margin-bottom:95px">
                                    <center><a :href='started_ed' class="buttonstyle btn filled-btn labtest-btn hvr-grow">
                                            Get Started </a></center>
                                    <br>
                                </div>
                                <div class="col-md-3"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="slide-2">
                <div class="header-bottom ed-banner2">
                    <div class="banner-wrap banner-color">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-12"
                                    style="color:white; text-align:center; font-weight: 600px !important">
                                    <div class="banner-contents" data-aos="fade-up" data-aos-delay="0">
                                        <br>
                                        <h1 style="color: white">E.D. Fast Acting Lozenges</h1>
                                        <br>
                                        <br>
                                        <br><br>
                                        <ul class="list-unstyled d-flex d-inline-flex m-0" style="display: flex;
                                        justify-content: space-between; gap: 30px; list-style: none; padding: 0;">
                                            <li class="pixel-2 pixel-3 banner-1-icon">
                                                <span>
                                                <img src="img/icons/no_need_to_plan.png"  class="img-fluid" alt="icon" />
                                                <br>
                                               No Need to plan </span>
                                            </li>
                                            <li class="pixel-2 pixel2 banner-1-icon">
                                                <span class="button-icon-pd">
                                                <img src="img/icons/no_need_to_wait.png" class="img-fluid" alt="icon" />
                                                <br>
                                                <!-- <span class="mobilePadding"> -->
                                                    No Need to Wait
                                               
                                            </span>
                                            </li>
                                            <li class="pixel-2">
                                                <span>
                                                <img src="img/icons/seize_the_moment.png" class="img-fluid" alt="icon" />
                                                <br>
                                                <!-- <span class="mobilePadding"> -->
                                                    Seize the Moment</span>
                                            </li>
                                        </ul>


                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-3"><br><br><br><br><br><br></div>
                                <div class="col-md-6" style="margin-bottom:15px; margin-top: 30px;">
                                    <center><a :href='started_ed' class="buttonstyle btn filled-btn labtest-btn hvr-grow">
                                            Get Started </a></center>
                                    <br>
                                </div>
                                <div class="col-md-3"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="slide-3">
                <div class="header-bottom covid2-banner">
                    <div class="banner-wrap" style="background-color:rgb(92, 216, 214, 0.7)">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-12" style="color:white; text-align:center; font-weight: 600px !important">
                                    <div class="banner-contents" data-aos="fade-up" data-aos-delay="0">
                                        <br><br><br><br><br>
                                        <h1>At Home PCR Saliva Covid Test</h1>
                                        <br>
                                        <h2>Easy, Accurate and Painless</h2>
                                        <br><br><br><br>
                                    </div>
                                </div>                            
                            </div>
                            <div class="row">
                                <div class="col-md-3"><br></div>
                                <div class="col-md-6" style="margin-bottom:30px">
                                    <center><a :href='started_covid19' class="buttonstyle btn filled-btn labtest-btn hvr-grow"> Order Kit </a></center>
                                    <br>    
                                </div>
                                <div class="col-md-3"></div>
                            </div>                       
                        </div>
                    </div>
                </div>
            </div>                                   -->
        </agile>
        <!-- <div class="partners-holder">
            <div class="container">
                <div class="partners-contents text-center" data-aos="fade-up" data-aos-delay="0">
                    <div class="owl-carousel owl-carousel-partner">
                        <div class="row">
                            <img src="img/icons/photo_2023-09-28 08.30.41.jpeg" alt="">
                        </div>
                        </div>

                </div>

                </div>

        </div> -->
        <!-- <div class="partners-holder">
            <div class="container">
                <div class="partners-contents text-center" data-aos="fade-up" data-aos-delay="0">
                    <div class="owl-carousel owl-carousel-partner">
                        <div class="row">
                            <div class="col-md-4"></div>
                            <div class="col-md-2 item" >
                                <div class="" >
                                    <a><img src="https://static.legitscript.com/seals/10869709.png" width="170" height="200" class="img-fluid" alt="logo" /></a>
                                </div>
                            </div>  
                            <div class="col-md-2 item">
                                <div class="">
                                    <a><img src="img/badge1.png" style="width:115px !important" width="170" height="200" class="img-fluid" alt="logo" /></a>
                                </div>
                            </div>                                                                                  
                        </div>
                        <div class="row">
                            <div class="col-md-3"></div>
                            <div class="col-md-6">
                                <h2 style="font-weight:800">AS SEEN ON</h2>
                            </div>
                        </div>                        
                        <div class="row">
                            <div class="col-md-3 item">
                                <div class="partners-img sponser-img">
                                    <a><img  src="img/thumbnails/NBC.png" style="background-color:white; padding:10px; border-radius:10px" width="400px"  class="img-fluid" alt="logo" /></a>
                                </div>
                            </div>
                            <div class="col-md-3 item">
                                <div class="partners-img sponser-img">
                                    <a><img src="img/thumbnails/abc.png" style="background-color:white; padding:10px; border-radius:10px" class="img-fluid" alt="logo" /></a>
                                </div>
                            </div>
                            <div class="col-md-3 item">
                                <div class="partners-img sponser-img">
                                    <a><img src="img/thumbnails/FOX.png" style="background-color:white; padding:10px; border-radius:10px" class="img-fluid" alt="logo" /></a>
                                </div>
                            </div>

                            <div class="col-md-2 item">
                                <div class="partners-img sponser-img">
                                    <a><img src="img/thumbnails/CBS.png" style="background-color:white; padding:10px; border-radius:10px" class="img-fluid" alt="logo" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>         -->
        <div class="how-holder">
            <div class="container">
                <div class="how-heading heading text-center" data-aos="fade-up" data-aos-delay="0">
                    <h1>How it Works</h1>
                    <p>Better Doctor handles everything from online evaluation to delivery of treatment and free ongoing
                        care.</p>
                </div>

                <div class="howit-wrap">
                    <div class="row m-0">
                        <div class="col-md-4 p-0">
                            <div class="howit-cont-wrap one" data-aos="fade-up" data-aos-delay="0">
                                <div class="howit-img">
                                    <img src="img/others/howit-imgg-1.png" width="295px" class="img-fluid rounded-circle"
                                        alt="howit-img-1">
                                    <div class="howit-num">
                                        <h3>1</h3>
                                    </div>
                                </div>
                                <div class="howit-contents">
                                    <h3 style="margin-top: 10px;">Online visit</h3>
                                    <p class="tab-margn" style="font-size: revert;
                                    text-align: inherit">Easy online visit, reviewed by certified medical
                                        professionals, designed to find the <b>BETTER</b> treatment option for you.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 p-0">
                            <div class="howit-cont-wrap two" data-aos="fade-up" data-aos-delay="0">
                                <div class="howit-img">
                                    <img src="img/others/howit-img-2.jpg" class="img-fluid  rounded-circle"
                                        alt="howit-img-1">
                                    <div class="howit-num two">
                                        <h3>2</h3>
                                    </div>
                                </div>
                                <div class="howit-contents">
                                    <h3 style="margin-top: 10px;">Fast deliveries</h3>
                                    <p class="tab-margn" style="font-size: revert;
                                    text-align: inherit">Fast shipping of any treatments prescribed<br> by your
                                        <b>BETTER</b> Doctor. So you can start your <b>BETTER</b> life!
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 p-0">
                            <div class="howit-cont-wrap three" data-aos="fade-up" data-aos-delay="0">
                                <div class="howit-img">
                                    <img src="img/others/howit-img-5.png" width="295px" class="img-fluid  rounded-circle"
                                        alt="howit-img-1">
                                    <div class="howit-num">
                                        <h3>3</h3>
                                    </div>
                                </div>
                                <div class="howit-contents">
                                    <h3 style="margin-top: 10px;">Ongoing care</h3>
                                    <p class="tab-margn" style="font-size: revert;
                                    text-align: inherit">Our medical professionals are available around the clock to
                                        answer any questions or concerns. We always have you covered for your <B>BETTER</b>
                                        tomorrow! </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="howit-btn text-center">
                        <a :href='started_home' class="mobilebutton btn how-btn common-btn hvr-float-shadow"><i
                                class="fa fa-stethoscope " aria-hidden="true"></i> Get started</a>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="homepersontest-holder common-holder">
          <div class="container">
              <div class="row">
                  <div class="col-md-6 d-block d-sm-block d-md-none">
                      <div class="hometreatment-img common-img" data-aos="fade-up" data-aos-delay="0" >
                          <img src="img/others/Why-Our-Treatments.webp" class="img-fluid" alt="post" />
                      </div>
                  </div>
                  <div class="col-md-6">
                      <div class="hometreatment-contents common-contents heading" data-aos="fade-up" data-aos-delay="0">
                          <h1>Why Our Treatments?</h1>
                          <h3>Virtual treatments available 24/7/365 allows for treatment on your schedule!</h3>
                          <p>With Certified Medical Professionals, Genuine Prescriptions , State of the Art Treatments, and Discreet Packaging. You can have a <b>BETTER</b> Tomorrow. </p>
                          <a href="https://getbetterdoctor.com/forms/dist/#/select-gender" class="mobilebutton btn hometreatment-btn common-btn hvr-float-shadow"><i class="fa fa-stethoscope" aria-hidden="true"></i> Get Treated</a>
                      </div>
                  </div>
                  <div class="col-md-6 d-none d-sm-none d-md-block">
                      <div class="hometreatment-img common-img" data-aos="fade-up" data-aos-delay="0">
                          <img src="img/others/Why-Our-Treatments.webp" class="img-fluid" alt="post" />
                      </div>
                  </div>
              </div>
          </div>
      </div> -->
        <!-- <div class="holder">
          <div class="hometreatment-holder common-holder">
              <div class="container">
                  <div class="row">
                      <div class="col-md-6">
                          <div class="hometest-img common-img" data-aos="fade-up" data-aos-delay="0">
                              <img src="img/others/Why-Our-At-Home-Tests.webp" class="img-fluid" alt="post" />
                          </div>
                      </div>
                      <div class="col-md-6">
                          <div class="hometest-contents common-contents heading"  data-aos="fade-up" data-aos-delay="0">
                              <h1>Why Our At Home Tests?</h1>
                              <h3>No need to Leave Home, Cost Effective, Fast Turn Around Time!</h3>
                              <p>With state of the art CLIA Certified Lab Partners, Overnight Shipping, and Cost Effective Rates. Getting tested at home has never been <B>BETTER</b>!</p>
                              <a href="https://getbetterdoctor.com/forms/dist/#/select-gender" class="mobilebutton btn hometest-btn common-btn hvr-float-shadow"><i class="fa fa-flask" aria-hidden="true"></i> Get Tested</a>
                          </div>
                      </div>
                  </div>
              </div>
          </div>



          <div class="homepersontest-holder common-holder">
              <div class="container">
                  <div class="row">
                      <div class="col-md-6">
                          <div class="homepersontest-contents common-contents heading" data-aos="fade-up" data-aos-delay="0" >
                              <h1>Why Our In Person Tests?</h1>
                              <h3>Testing in your city, on your schedule requested by Certified Medical Professionals!</h3>
                              <p>Skip the wait, Fast Results, In Person Care, and No In Person Doctor Visit Required! Get tested today and start feeling <b>BETTER</b> about your health.</p>
                              <a href="https://getbetterdoctor.com/forms/dist/#/select-gender" class="mobilebutton2 btn homepersontest-btn common-btn hvr-float-shadow"><i class="fa fa-location-arrow" aria-hidden="true"></i> Get Tested Near Me</a>
                          </div>
                      </div>
                      <div class="col-md-6">
                          <div class="homepersontest-img common-img" data-aos="fade-up" data-aos-delay="0" >
                              <img src="img/others/Why-Our-In-Person-Tests.webp" class="img-fluid" alt="post" />
                          </div>
                      </div>                      
                  </div>
              </div>
          </div> -->


        <!-- <div class="faq-holder">
              <div class="container">
                  <div class="faq-heading heading text-center" data-aos="fade-up" data-aos-delay="0" >
                      <h1>FAQ</h1>
                  </div>
                  <div class="faq-contents" data-aos="fade-up" data-aos-delay="0">
                      <div class="accordion" id="accordion1">
                          <div class="row">
                              <div class="col-md-6">
                                  <div class="card">
                                      <div class="panel-heading">
                                          <a class="card-header" data-toggle="collapse" href="#collapseOne" data-parent="#accordion1" aria-expanded="false">
                                              <h4 class="card-title">
                                                  <span>What is Better-Doctor?</span>
                                                  <i class="indicator fa fa-chevron-circle-right"></i>
                                              </h4>
                                          </a>
                                      </div>
                                      <div id="collapseOne" class="collapse" aria-expanded="false" data-parent="#accordion1" style="">
                                          <div class="card-body">
                                              <p>Better Doctor is a <b>BETTER</B> all in one medical testing and treatment option online. Allowing Patients to skip waiting rooms and get the treatments and tests they need faster.</p>
                                          </div>
                                      </div>
                                  </div>

                                  <div class="card">
                                      <div class="panel-heading">
                                          <a class="card-header" data-toggle="collapse" href="#collapseTwo" data-parent="#accordion1" aria-expanded="false">
                                              <h4 class="card-title">
                                                  <span>How much does it cost?</span>
                                                  <i class="indicator fa fa-chevron-circle-right"></i>
                                              </h4>
                                          </a>
                                      </div>
                                      <div id="collapseTwo" class="collapse" aria-expanded="false" data-parent="#accordion1" style="">
                                          <div class="card-body">
                                              <p>Tests and Treatments vary in price but with Better-Doctor you can rest assured you'll be paying a <b>BETTER</b> price for your personalized Health Care.</p>
                                          </div>
                                      </div>
                                  </div>

                                  <div class="card">
                                      <div class="panel-heading">
                                          <a class="card-header" data-toggle="collapse" href="#collapseThree" data-parent="#accordion1" aria-expanded="false">
                                              <h4 class="card-title">
                                                  <span>Is better-doctor insurance?</span>
                                                  <i class="indicator fa fa-chevron-circle-right"></i>
                                              </h4>
                                          </a>
                                      </div>
                                      <div id="collapseThree" class="collapse" aria-expanded="false" data-parent="#accordion1" style="">
                                          <div class="card-body">
                                              <p>Better-Doctor is not insurance, Better-Doctor is a <b>BETTER</b> personalized healthcare solution.</p>
                                          </div>
                                      </div>
                                  </div>
                              </div>

                              <div class="col-md-6">
                                  <div class="card">
                                      <div class="panel-heading">
                                          <a class="card-header" data-toggle="collapse" href="#collapseFour" data-parent="#accordion1" aria-expanded="false">
                                              <h4 class="card-title">
                                                  <span>How competent are your healthcare professionals?</span>
                                                  <i class="indicator fa fa-chevron-circle-right"></i>
                                              </h4>
                                          </a>
                                      </div>
                                      <div id="collapseFour" class="collapse" aria-expanded="false" data-parent="#accordion1" style="">
                                          <div class="card-body">
                                              <p>Better-Doctor exclusively works with US Certified Medical Professionals, ensuring <b>BETTER</B> care. </p>
                                          </div>
                                      </div>
                                  </div>

                                  <div class="card">
                                      <div class="panel-heading">
                                          <a class="card-header" data-toggle="collapse" href="#collapseFive" data-parent="#accordion1" aria-expanded="false">
                                              <h4 class="card-title">
                                                  <span>Is there a long term commitment?</span>
                                                  <i class="indicator fa fa-chevron-circle-right"></i>
                                              </h4>
                                          </a>
                                      </div>
                                      <div id="collapseFive" class="collapse" aria-expanded="false" data-parent="#accordion1" style="">
                                          <div class="card-body">
                                              <p>No! Part of being <b>BETTER</b> is our flexibility!</p>
                                          </div>
                                      </div>
                                  </div>

                                  <div class="card">
                                      <div class="panel-heading">
                                          <a class="card-header" data-toggle="collapse" href="#collapseSix" data-parent="#accordion1" aria-expanded="false">
                                              <h4 class="card-title">
                                                  <span>Is my information safe?</span>
                                                  <i class="indicator fa fa-chevron-circle-right"></i>
                                              </h4>
                                          </a>
                                      </div>
                                      <div id="collapseSix" class="collapse" aria-expanded="false" data-parent="#accordion1" style="">
                                          <div class="card-body">
                                              <p>Yes! We use only the best encryption and storage of all data provided by you.</p>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div> -->
        <!-- <div class="footer-overlay">
              <img src="img/others/footer-overlay.webp" class="img-fluid" width="100%" alt="overlay" />
          </div> -->
        <!-- </div> -->
        <div class="common-holder">
            <div>
                <div class="why-holder">
                    <div class="container">
                        <div class="why-heading heading text-center" data-aos="fade-up" data-aos-delay="0">
                            <h1>Why Better Doctor?</h1>
                            <p>We deliver a <b>BETTER</b> tomorrow through our innovative 24/7 telemedicine platform enabled
                                by cutting edge certified pharmacies, certified medical professionals and lab partners</p>
                        </div>
                        <div class="why-contents">
                            <div class="row">
                                <div class="col-4 col-sm-4 col-md-4">
                                    <div class="why-wrap" data-aos="fade-up" data-aos-delay="0">
                                        <div class="row m-0">
                                            <div class="col-md-4 p-0">
                                                <div class="why-icon">
                                                    <img src="img/icons/why-1.webp" class="img-fluid" alt="icon" />
                                                </div>
                                            </div>
                                            <div class="col-md-8 p-0">
                                                <div class="why-details">
                                                    <h6>Easy to Use Online Visit</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4 col-sm-4 col-md-4">
                                    <div class="why-wrap" data-aos="fade-up" data-aos-delay="0">
                                        <div class="row m-0">
                                            <div class="col-md-4 p-0">
                                                <div class="why-icon">
                                                    <img src="img/icons/why-2.webp" class="img-fluid" alt="icon" />
                                                </div>
                                            </div>
                                            <div class="col-md-8 p-0">
                                                <div class="why-details">
                                                    <h6>Certified Medical Professionals</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4 col-sm-4 col-md-4">
                                    <div class="why-wrap" data-aos="fade-up" data-aos-delay="0">
                                        <div class="row m-0">
                                            <div class="col-md-4 p-0">
                                                <div class="why-icon">
                                                    <img src="img/icons/why-3.webp" class="img-fluid" alt="icon" />
                                                </div>
                                            </div>
                                            <div class="col-md-8 p-0">
                                                <div class="why-details">
                                                    <h6>Control Your Well Being</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-4 col-sm-4 col-md-4">
                                    <div class="why-wrap" data-aos="fade-up" data-aos-delay="0">
                                        <div class="row m-0">
                                            <div class="col-md-4 p-0">
                                                <div class="why-icon">
                                                    <img src="img/icons/why-4.webp" class="img-fluid" alt="icon" />
                                                </div>
                                            </div>
                                            <div class="col-md-8 p-0">
                                                <div class="why-details">
                                                    <h6>Personalized Treatments</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4 col-sm-4 col-md-4">
                                    <div class="why-wrap" data-aos="fade-up" data-aos-delay="0">
                                        <div class="row m-0">
                                            <div class="col-md-4 p-0">
                                                <div class="why-icon">
                                                    <img src="img/icons/why-5.webp" class="img-fluid" alt="icon" />
                                                </div>
                                            </div>
                                            <div class="col-md-8 p-0">
                                                <div class="why-details">
                                                    <h6>Secure and Confidential</h6>
                                                    <h6>HIPAA Compliant</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4 col-sm-4 col-md-4">
                                    <div class="why-wrap" data-aos="fade-up" data-aos-delay="0">
                                        <div class="row m-0">
                                            <div class="col-md-4 p-0">
                                                <div class="why-icon">
                                                    <img src="img/icons/why-6.webp" class="img-fluid" alt="icon" />
                                                </div>
                                            </div>
                                            <div class="col-md-8 p-0">
                                                <div class="why-details">
                                                    <h6>24/7 Access</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="testing-btn text-center" data-aos="fade-up" data-aos-delay="0">
                                <a :href='started_home'
                                    class="mobilebutton btn filled-btn testing-btn hvr-wobble-vertical"><i
                                        class=" fa fa-stethoscope" aria-hidden="true"></i> Get Started!</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="start-holder common-holder">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="start-contents heading" data-aos="fade-up" data-aos-delay="0">
                                <h1>Ready to start your <b>BETTER</b> tomorrow?</h1>
                                <a :href='started_home' class="mobilebutton btn start-btn common-btn hvr-push">Get
                                    Started!</a>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="start-info">
                                <div class="row">
                                    <div class="col-6 col-sm-6 col-md-6">
                                        <div class="start-box text-center" data-aos="fade-up" data-aos-delay="0">
                                            <img src="img/icons/start-1.webp" class="img-fluid" alt="icon" />
                                            <h6>Fast <br> Shipping</h6>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-6">
                                        <div class="start-box text-center" data-aos="fade-up" data-aos-delay="0">
                                            <img src="img/icons/start-2.webp" class="img-fluid" alt="icon" />
                                            <h6>Easy <br> Visit</h6>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6 col-sm-6 col-md-6">
                                        <div class="start-box text-center" data-aos="fade-up" data-aos-delay="0">
                                            <img src="img/icons/start-3.webp" class="img-fluid" alt="icon" />
                                            <h6>No Long <br> Term Commitment</h6>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-6">
                                        <div class="box-pd-btm start-box text-center" data-aos="fade-up" data-aos-delay="0">
                                            <img src="img/icons/start-4.webp" class="img-fluid" alt="icon" />
                                            <h6>Free <br> Follow-Ups</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import Header from "../components/Header";
import Footer from "../components/Footer";
import Config from '../../config/prod.env';
import { VueAgile } from 'vue-agile';


export default {
    name: 'Home',
    components: {
        Footer,
        Header,
        agile: VueAgile,
    },
    data() {
        return {
            started_home: Config.STARTED_HOME,
            started_ed: Config.STARTED_ED,
            started_covid19: Config.STARTED_COVID19,
            left: '<svg x="0px" y="0px" viewBox="0 0 24 24"><path d="M16.2,21c0.3,0,0.5-0.1,0.7-0.3c0.4-0.4,0.4-1,0-1.4L9.6,12L17,4.7c0.4-0.4,0.4-1,0-1.4c-0.4-0.4-1-0.4-1.4,0L6.8,12l8.8,8.7C15.7,20.9,16,21,16.2,21z"/></svg>',
            right: '<svg x="0px" y="0px" viewBox="0 0 24 24"><path d="M7.8,21c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l7.4-7.3L7,4.7c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0l8.8,8.7l-8.8,8.7C8.3,20.9,8,21,7.8,21z"/></svg>',
            myOptions: {
                navButtons: false,
                responsive: [
                    {
                        breakpoint: 600,
                        settings: {
                            dots: false
                        }
                    },

                    {
                        breakpoint: 900,
                        settings: {
                            navButtons: false,
                            dots: true,
                            infinite: true
                        }
                    }
                ]
            }
        }
    },

    methods: {

        clickMethod: function () {
            // alert('sss')
            window.location.assign('https://getbetterdoctor.com/forms/dist/#/treatments/male')
        },
        clickMethodWomen: function () {
            // alert('sss')
            window.location.assign('https://getbetterdoctor.com/forms/dist/#/treatments/female')
        },
        clickMethodAtHome: function () {
            // alert('sss')
            // window.location.assign('https://getbetterdoctor.com/forms/dist/#/treatments/female')
            this.$router.push({ name: 'Testing' })
        },
        clickMethodAtPerson: function () {
            // alert('sss')
            // window.location.assign('https://getbetterdoctor.com/forms/dist/#/treatments/female')
            this.$router.push({ name: 'Testing' })
        }
    },
    mounted() {
        //  this.$nextTick(() => {
        //     const plugin = document.createElement("script");
        //     plugin.setAttribute(
        //     "src",
        //     "http://static.legitscript.com/seals/10869709.js"
        //     );
        //     plugin.async = true;
        //     document.body.appendChild(plugin);
        //  })

    }
}
</script>

<style scoped>
.icon {
    color: white;
}

@media only screen and (min-width: 1200px) {
    .showdesktop {
        display: block;
    }
}

@media only screen and (min-width: 768px) and (max-width: 900px) {
    .tab-margn {
        padding: 0px 20px 20px 20px !important;
    }

    .mobilePadding {
        font-size: 20px !important;
    }
}

@media only screen and (min-width: 410px) and (max-width: 412px) {
    .pixel-2 {
        margin-right: 27px !important;
    }

    .pixel2 {
        margin-left: -10px !important;
    }

    .pixel-3 {
        margin-left: 20px;
    }

    .pixel-02 {
        margin-left: -7px !important;
    }
}

@media only screen and (min-width: 319px) and (max-width: 320px) {
    .pixel-2 {
        margin-right: 27px !important;
    }

    .pixel2 {
        margin-left: -8px !important;
    }

    .pixel-3 {
        margin-left: 6px;
    }

    .pixel-02 {
        margin-left: -7px !important;
    }
}

@media only screen and (min-width: 374px) and (max-width: 375px) {
    .pixel-2 {
        margin-right: 27px !important;
    }

    .pixel2 {
        margin-left: -8px !important;
    }

    .pixel-3 {
        margin-left: 15px;
    }

    .pixel-02 {
        margin-left: -7px !important;
    }
}

@media only screen and (min-width: 389px) and (max-width: 390px) {
    .pixel-2 {
        margin-right: 27px !important;
    }

    .pixel2 {
        margin-left: -9px !important;
    }

    .pixel-3 {
        margin-left: 15px;
    }

    .pixel-02 {
        margin-left: -7px !important;
    }
}

@media only screen and (min-width: 392px) and (max-width: 393px) {
    .pixel-2 {
        margin-right: 27px !important;
    }

    .pixel2 {
        margin-left: -12px !important;
    }

    .pixel-3 {
        margin-left: 13px;
    }

    .pixel-02 {
        margin-left: -7px !important;
    }
}

@media only screen and (min-width: 427px) and (max-width: 428px) {
    .pixel-2 {
        margin-right: 27px !important;
    }

    .pixel2 {
        margin-left: -10px !important;
    }

    .pixel-3 {
        margin-left: 15px;
    }

    .pixel-02 {
        margin-left: -7px !important;
    }

    .mobilePadding {
        font-size: 20px !important;
    }
}

@media only screen and (min-width: 359px) and (max-width: 360px) {
    .pixel-2 {
        margin-right: 27px !important;
    }

    .pixel2 {
        margin-left: -5px !important;
    }

    .pixel-3 {
        margin-left: 22px;
    }

    .pixel-02 {
        margin-left: -7px !important;
    }
}

@media only screen and (min-width: 413px) and (max-width: 414px) {
    .pixel-2 {
        margin-right: 27px !important;
    }

    .pixel2 {
        margin-left: -12px !important;
    }

    .pixel-3 {
        margin-left: 20px;
    }

    .pixel-02 {
        margin-left: -7px !important;
    }

    .pd_leftt {
        padding-left: 58px !important;
    }
}
</style>