<template>
    <div id="app">
        <Header/>
        <router-view/>
        <Footer/>
    </div>
</template>

<script>
import Header from "./components/Header";
import Footer from "./components/Footer";
export default {
    components: {Footer, Header},
    mounted(){
        console.clear();
    }
}
</script>

<style scoped>
.toasted.bubble {
    background-color: #0EB8B7 !important;
}
</style>