import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/Home.vue')
        }
    },
    {
        path: '/faq',
        name: 'Faq',
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/Faq.vue')
        }
    },
    {
        path: '/hairloss',
        name: 'hairloss',
        
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/hairloss.vue')
        }
    },
    {
        path: '/covid-19',
        name: 'covid19',
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/covid19.vue')
        }
    },
    {
        path: '/female-covid-19',
        name: 'female-covid19',
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/female/covid19.vue')
        }
    },
    {
        path: '/std',
        name: 'std',
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/std.vue')
        }
    },
    {
        path: '/fertility-test',
        name: 'fertilitytest',
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/fertilitytest.vue')
        }
    },
    {
        path: '/hormone-test',
        name: 'hormone',
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/hormone.vue')
        }
    },
    {
        path: '/skincare',
        name: 'skincare',
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/skin-care.vue')
        }
    },
    {
        path: '/female-skincare',
        name: 'female-skincare',
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/female/skin-care.vue')
        }
    },    
    {
        path: '/birthcontrol',
        name: 'birthcontrol',
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/birthcontrol.vue')
        }
    },
    {
        path: '/quitsmoking',
        name: 'quitsmoking',
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/quitsmoking.vue')
        }
    },
    {
        path: '/screamcream',
        name: 'screamcream',
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/screamcream.vue')
        }
    },
    {
        path: '/mensexualproblem',
        name: 'mensexualproblem',
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/mensexualproblem.vue')
        }
    },
    {
        path: '/weightloss',
        name: 'weightloss',
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/weightloss.vue')
        }
    },
    {
        path: '/female-weightloss',
        name: 'female-weightloss',
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/female/weightloss.vue')
        }
    },    
    {
        path: '/herpes',
        name: 'herpes',
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/herpes.vue')
        }
    },     
    {
        path: '/female-herpes',
        name: 'female-herpes',
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/female/herpes.vue')
        }
    },        
    {
        path: '/allergy',
        name: 'allergy',
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/allergy.vue')
        }
    },  
    {
        path: '/depression',
        name: 'depression',
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/depression.vue')
        }
    },   
    {
        path: '/labtesting',
        name: 'Testing',
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/Testing')
        }
    },
    {
        path: '/treatments',
        name: 'Treatments',
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/Treatments.vue')
        }
    },  
    {
        path: '/privacy-policy',
        name: 'Privacy',
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/PrivacyPolicy.vue')
        }
    },
    {
        path: '/terms-and-condition',
        name: 'Terms',
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/TermsAndConditions.vue')
        }
    }
]

const router = new VueRouter({
    scrollBehavior (to, from, savedPosition) {
        return {
            x: 0, y: 0,
            //behavior: 'smooth',
        }
    },
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
