<template>
    <div>
        <header>
            <div class="header-top">
                <div class="container">
                    <div class="row">
                        <!-- <div class="col-6 col-sm-10 col-lg col-xs-4 col-md-3"></div> -->
                        <div class="col-6 col-sm-2 col-lg-7 col-xs-4 col-md-9">
                            <div class="header-options">
                                <ul class="list-unstyled d-flex d-inline-flex m-0">
                                    <li><a href="tel:+18557851636"><i class="fa fa-phone"></i> <span>Call Us +1 (888)-286-8124</span></a></li>
                                    <li><a href="mailto:support@getbetterdoctor.com"><i class="fa fa-envelope-o"></i> <span>support@getbetterdoctor.com</span></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div> 
                <nav>
                  <a href="/"><img src="img/footer-logo.png" alt="" class="logo_f  logomobile hidelogodesktop" aria-hidden="true" ></a>
                  <a onclick=""  tabindex="0" class="mobile-menu-trigger"  style="margin-top:-15px; padding-left:90%; background: white">
                    <i  class="fa fa-bars" style="color: #177E89 " aria-hidden="true">
                    </i>
                  </a>
                  <ul class="menu menu-bar tab-menu-style">
                    <div class="hidee div-mrgn col-6 col-md-4 col-lg-4 col-xl-5">
                      <a href="/"><img src="img/footer-logo.png" alt="" class="logo_f logotab hideLogoMb logo-mrgin" aria-hidden="true" style="width:150px;"></a>
                    </div>
                      <li >
                        <a onclick=""  tabindex="0"  ref="myBtn" @mouseover="checkMouseover" class="menu-link menu-bar-link" aria-haspopup="true" v-on:click="showdiv">Treatments</a>
                        <ul class="tab-menu-style-sub mega-menu mega-menu--multiLevel" style="border-top: 1px solid #f0f0f0" id="xyz"   
                        :class="{ hide2: hidetreatments }"                   
                        >
                          <li class="grid-list-main">
                            <div>
                              <!-- <span><i class="two-tone-gray-icon-man-health icon" aria-hidden="true"></i></span> -->
                            <!-- <span><img src="/img/icons/man_health_gray.svg" class="icon" alt=""></span> -->
                            </div>
                            <a style="width: 300px;" class="menu-link mega-menu-link marginLeftMb" aria-haspopup="true"><span><img src="img/icons/man_health_gray.svg" class="icon" alt=""></span><strong  > Men’s Treatments</strong></a>
                            <ul>
                              <div class="row">
                                <div class="col col-12 " >
                                    <ul class="menu menu-list grid-list" style="margin-left: 20px;" >
                                          <li style="border-right: 1px solid #f0f0f0; border-left: 1px solid #f0f0f0">
                                              <a :href='mensexualproblem' class="menu-link menu-list-link">Erectile Dysfunction</a>
                                          </li>
                                          <!-- <li style="border-right: 1px solid #f0f0f0">
                                              <a :href='herpes' class="menu-link menu-list-link">Herpes</a>
                                          </li> -->
                                          <li style="border-right: 1px solid #f0f0f0;">
                                              <a :href='skincare' class="menu-link menu-list-link">Skin Care</a>
                                          </li>
                                          <li style="border-right: 1px solid #f0f0f0; border-left: 1px solid #f0f0f0">
                                              <a :href='hairloss' class="menu-link menu-list-link">Hair Loss</a>
                                          </li>
                                          <li style="border-right: 1px solid #f0f0f0; border-left: 1px solid #f0f0f0">
                                              <a :href='weightloss' class="menu-link menu-list-link">Weight Loss</a>
                                          </li>
                                    </ul>
                                </div>
                              </div>
                            </ul>
                          </li>
                          <li class="grid-list-main">
                            <div>
                            <!-- <span><img src="/img/icons/womens_health_gray.svg" class="icon" alt=""></span> -->
                            </div>
                            <a style="width: 300px;" class="menu-link mega-menu-link marginLeftMb" aria-haspopup="true"><span><img src="img/icons/womens_health_gray.svg" class="icon" alt=""></span> <strong  >Women’s Treatments</strong> </a>
                                  
                              <ul>
                                <div class="row">
                                    
                                  <div class="col col-12">
                                    <ul class="menu menu-list grid-list" style="margin-left: 20px;">
                                      <li style="border-right: 1px solid #f0f0f0; border-left: 1px solid #f0f0f0">
                                          <a :href='scream_cream' class="menu-link menu-list-link">Scream Cream</a>
                                      </li>
                                      <!-- <li>
                                          <a :href='female_herpes' class="menu-link menu-list-link">Herpes</a>
                                      </li> -->
                                      <li style="border-right: 1px solid #f0f0f0; border-left: 1px solid #f0f0f0">
                                          <a :href='female_skincare' class="menu-link menu-list-link">Skin Care</a>
                                      </li>
                                      <li style="border-right: 1px solid #f0f0f0;border-left: 1px solid #f0f0f0">
                                          <a :href='female_weightloss' class="menu-link menu-list-link">Weight Loss</a>
                                      </li>
                                    </ul>
                                  </div> 
                                </div>
                              </ul>
                          </li>
                          <button v-on:click="hidediv" class="custombutton mobile-menu-back-item">
                            <a class="">Back</a>                                  
                          </button>
                        </ul>
                      </li>
                      <li>
                        <a onclick=""  tabindex="0"  class="menu-link menu-bar-link" aria-haspopup="true" v-on:click="showdiv">Testing</a>
                        <ul class="mega-menu mega-menu--multiLevel"
                        :class="{ hide2: hidetreatments }"                                         
                        >
                          <li class="grid-list-main">
                            <div>
                              <!-- <span><i class="two-tone-gray-icon-man-health icon" aria-hidden="true"></i></span> -->
                            <!-- <span><img src="img/icons/man_health_gray.svg" class="icon" alt=""></span> -->
                            </div>
                            <a style="width: 300px;" class="menu-link mega-menu-link marginLeftMb" aria-haspopup="true"><span><img src="img/icons/man_health_gray.svg" class="icon" alt=""></span><strong > Men’s Testing</strong></a>
                            <ul>
                              <div class="row">
                                <div class="col col-12">
                                    <ul class="menu menu-list grid-list" style="margin-left: 20px;">
                                      <!-- <li style="border-right: 1px solid #f0f0f0;border-left: 1px solid #f0f0f0">
                                          <a :href='covid_19' class="menu-link menu-list-link">COVID-19</a>
                                      </li> -->
                                      <!-- <li style="border-right: 1px solid #f0f0f0;">
                                          <a :href='std' class="menu-link menu-list-link">STD</a>
                                      </li> -->
                                      <!-- <li style="border-right: 1px solid #f0f0f0">
                                          <a :href='hormone_test' class="menu-link menu-list-link">Hormone Test</a>
                                      </li> -->
                                      <li style="border-right: 1px solid #f0f0f0;">
                                            <a class="menu-link menu-list-link">Coming Soon</a>
                                        </li>
                                    </ul>
                                </div>                                   
                              </div>
                            </ul>
                          </li>
                          <li class="grid-list-main">
                            <div>
                            <!-- <span><img src="img/icons/womens_health_gray.svg" class="icon" alt=""></span> -->
                            </div>
                            <a style="width: 300px;" class="menu-link mega-menu-link marginLeftMb" aria-haspopup="true"><span><img src="img/icons/womens_health_gray.svg" class="icon" alt=""></span> <strong >Women’s Testing</strong> </a>
                                    
                            <ul>
                              <div class="row">
                                  <div class="col col-12">
                                    <ul class="menu menu-list grid-list" style="margin-left: 20px;">
                                        <!-- <li style="border-right: 1px solid #f0f0f0;border-left: 1px solid #f0f0f0">
                                          <a :href='female_covid_19' class="menu-link menu-list-link">COVID-19</a>
                                        </li> -->
                                        <!-- <li style="border-right: 1px solid #f0f0f0;">
                                            <a :href='std' class="menu-link menu-list-link">STD</a>
                                        </li>
                                        <li style="border-right: 1px solid #f0f0f0;">
                                            <a :href='fertility_test' class="menu-link menu-list-link">Fertility Test</a>
                                        </li> -->
                                        <li style="border-right: 1px solid #f0f0f0;">
                                            <a class="menu-link menu-list-link">Coming Soon</a>
                                        </li>
                                    </ul>
                                  </div>
                              </div>
                            </ul>
                          </li>                           
                          <button v-on:click="hidediv" class="custombutton mobile-menu-back-item">
                            <a class="">Back</a>                                  
                          </button>
                        </ul>
                      </li>
                      <li>
                          <a :href='faq' class="menu-link menu-bar-link">FAQ</a>
                      </li>
                      <li>
                          <a :href='login' class="menu-link menu-bar-link">Login</a>
                      </li>
                      <li class="mobile-menu-header">
                      </li>
                      <li class="nav-item login pd_btn" style="">
                          <a class="btn login-btn" :href='started_home'> Get Started </a>
                      </li>   
                  </ul>
                </nav>
            </div>
        </header>
    </div>
</template>


<script>
import Config from '../../config/prod.env';

export default {
    name: "Header",
    data() {
      return {
        hidetreatments: false,
        scream_cream: Config.SCREAM_CREAM,
        herpes: Config.HERPES,
        weightloss: Config.WEIGHTLOSS,
        skincare: Config.SKIN_CARE,
        hairloss: Config.HAIRLOSS,
        mensexualproblem: Config.ERECTILE_DYSFUNCTION,
        covid_19: Config.COVID_19,
        std: Config.STD,    
        hormone_test: Config.HORMONE_TEST,
        fertility_test: Config.FERTILITY_TEST, 
        faq: Config.FAQ,      
        started_home: Config.STARTED_HOME,
        login: Config.LOGIN,    
        female_herpes: Config.Female_HERPES,
        female_skincare: Config.Female_SKIN_CARE,
        female_weightloss: Config.Female_WEIGHTLOSS,
        female_covid_19: Config.Female_COVID_19,
      }
    },
    mounted() {
        AOS.init({
            duration: 1200,
        })
        function toggleChevron(e) {
            $(e.target)
                .prev('.panel-heading')
                .find("i.indicator")
                .toggleClass('fa-chevron-circle-down fa-chevron-circle-right');
        }
        $('#accordion1').on('hidden.bs.collapse', toggleChevron);
        $('#accordion1').on('shown.bs.collapse', toggleChevron);
        window.onscroll = function() {scrollFunction()};

        window.onscroll = function() {myFunction()};

        var navbar = document.getElementById("menu-wrap");
        var sticky = navbar.offsetTop;

        function myFunction() {
            if (window.pageYOffset >= sticky) {
                navbar.classList.add("sticky")
            } else {
                navbar.classList.remove("sticky");
            }
        }
    },
    methods: {
        // mobilemenu2: function(e) {
        // if (window.innerWidth < 885) {
        //   alert(e.target.href)
        // window.location.reload(e.target.href);
        // }
        // },
        hidediv: function(){
          this.hidetreatments = !this.hidetreatments;
        },
        showdiv: function(){
          {this.hidetreatments = false}
        },
         checkMouseover: function() {
            console.log('mouseover')
            void(0)
             this.$nextTick(() => {
           });     
        },
        showAlert() {
      alert('Mobile Menu Trigger Clicked!');
    }
        
    }

    
}
</script>

<style lang="scss" scoped>

@media only screen and (max-width: 320px) {
  .menu-link {
    padding: 17px 25px !important;
    background: #ffffff;
    color: #177E89;
    transition: background .2s, color .2s;
    position: relative;
    z-index: 1;
    &[aria-haspopup="true"] {
      padding-right: 40px;
      &:after {
        content: "";
        background-image: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/1397521/arrowRight.svg#accent');
        background-size: 14px;
        width: 14px;
        height: 14px;
        font-size:12px;
        position: absolute;
        // right: 10px;
        top:50%;
        transform: translateY(-50%);
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .hideDivMb{
      display: none;
    }
    .pd_btn{
      padding-top: 50% !important;
    }
    .grid-list {
      display: grid;
      grid-template-columns: repeat(2, 1fr) !important;
    }
    .hideLogoMb{
      display: none;
    }
    .bgLogoMb{
      background-image: url('/img/footer-logo.png');
    }
}
.icon{
  // margin:20px 0px 0px 87%;
  color: #177E89;
}
.grid-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

nav {
   ul, li {
     list-style: none;
     padding: 0;
     margin: 0;
  } 
  a {
    display: block;
    text-decoration: none;
    &:hover, &:visited {
      text-decoration: none;
    }
  }
}

.menu-bar {
  background: #ffffff;
  display: flex;
}

.menu-link {
  padding: 27px 25px;
  background: #ffffff;
  color: #177E89;
  transition: background .2s, color .2s;
  position: relative;
  z-index: 1;
  &[aria-haspopup="true"] {
    padding-right: 40px;
    &:after {
      content: "";
      background-image: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/1397521/arrowRight.svg#accent');
      background-size: 14px;
      width: 14px;
      height: 14px;
      font-size:12px;
      position: absolute;
      // right: 10px;
      top:50%;
      transform: translateY(-50%);
    }
  }
}

.mega-menu-header {
  font-size: 1.2em;
  text-transform: uppercase;
  font-weight: bold;
  color: darken(#177E89, 5%);
}

.mega-menu {
  background: #ffffff;
  z-index: 10;
}

.mega-menu--multiLevel {
  flex-direction: column;
}


// ------------------ MEDIA QUERIES
@media all and (min-width: 950px + 1px) and (max-width:1280px) {
  .marginLeftMb{
  margin-left:-50% !important;
  margin-top: 5px;
  }
}
@media all and (min-width: 950px + 1px) {
  // Desktop only
  .marginLeftMb{
  margin-left:15% ;
  margin-top: 5px;
  }
  .logo-mrgin {
    padding-top: 10px !important;
  }
  .div-mrgn {
    margin-bottom: -13px;
  }
.grid-list-main {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.logo_f {
    padding-left: 23px;
    padding-top: 3px;
    margin-right: 50px;
}
  .nav {
    margin-top: 50px;
    background: #ffffff;
    > nav {
      max-width: 900px;
      margin: 0 auto;
    }
  }

  .menu {
    [aria-haspopup="true"] {
      ~ ul {
        display: none;
      }
    }
  }

  .menu-bar {
    position: relative;
    > li {
      > [aria-haspopup="true"] {
        // STYLING: Down arrow on desktop
        &:after {
            background-image: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/1397521/arrowBottom.svg#accent');
        }
        &:hover {
          &:after {
            background-image: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/1397521/arrowBottom.svg#light');
          }
        }

        // FUNCTIONALITY: Open mega menu
        &:after {
          ~ ul {
            display: flex;
            transform-origin: top;
            animation: dropdown .2s ease-out;
          }
        }
        // FUNCTIONALITY: Keep mega menu open
        ~ ul {
          &:hover {
            display: flex;
          }
        }
      }

      // FUNCTIONALITY: Keep mega menu open
      &:hover {
        > [aria-haspopup="true"] {
          ~ ul {
            display: flex;
            
          }
        }
      }

      // STYLING: Hover/Active state
      > [aria-haspopup="true"]:focus,
      &:focus-within > [aria-haspopup="true"],
      &:hover > a {
        background: #177E89;
        color: #ffffff;
        &:after {
          background-image: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/1397521/arrowTop.svg#light');
        }
        
      }
    }
  }

  .mega-menu {
    // LAYOUT: Mega menu
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 245px !important;
    // FUNCTIONALITY: Keep mega menu open
    &:hover {
      display: none;
    } 

    a {
      &:hover {
        background: tint(#177E89, 85%);
        color: darken(#177E89, 5%);
        
      }

    }
  }
 
  


  .mega-menu--multiLevel {
    // LAYOUT: Multi level columns
    > li {
      width: 100/3 + 0%;
      > [aria-haspopup="true"] {
        ~ ul {
          left: 100/3 + 0%;
          width: 100/3 + 0%;
          ul {
            width: 100%;
            left: 100%;
          }
        }
      }
    }

    li {
      // FUNCTIONALITY: Opening flyouts
      &:hover {
        > [aria-haspopup="true"] {
          ~ ul {
            display: block;
            transform-origin: left;
            animation: flyout .2s ease-out;
          }
        }
      }

      // FUNCTIONALITY: Keeping flyouts open
      &:focus-within {
        > [aria-haspopup="true"] {
          ~ ul {
            display: block;
          }
        }
      }

      // STYLING: Flyout link active states
      &:hover, &:focus-within {
        > [aria-haspopup="true"], > a {
          background: tint(#177E89, 85%);
          color: darken(#a8b9bb, 5%);
        }
      }
    }
    [aria-haspopup="true"] {
      ~ ul, & {
        // border-left: 1px solid #f0f0f0;
        // FUNCTIONALITY: Keeping flyouts open
        &:hover {
          display: block;
        }
      }
      // LAYOUT: Flyouts
      ~ ul {
        position: absolute;
        top: 0;
        height: 100%;
      }
    }
  }


  // STYLING: Flat mega menu columns
  .mega-menu--flat {
    > * {
      flex: 1;
    }
  }

  // Hide mobile specific elements
  .mobile-menu-trigger, .mobile-menu-header, .mobile-menu-back-item {
    display: none;
  }
  .mobile-menu-trigger1, .mobile-menu-header, .mobile-menu-back-item {
    display: none;
  }
}



@media all and (max-width: 950px) {
.grid-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.hidee {
  display: none !important;
}
.custombutton {
  background-color: white !important;
  margin-right: 60% !important;
  margin-left: 5% !important;
  margin-top: 5% !important;
  border: 2px solid #0db8b6;
  border-radius: 50px !important;
  color: #0db8b6 !important;
  font-weight: 900;
}
.pd_btn {
    display: flex;
    justify-content: right;
    padding-right: 15px;
}
  .nav {
    padding: 20px;
  }

  .mobile-menu-trigger, .mobile-menu-header, .mobile-menu-back-item {
    display: block;
  }
  
  .mobile-menu-trigger1, .mobile-menu-header, .mobile-menu-back-item {
    display: block;
  }

  .mobile-menu-trigger {
    background: #177E89;
    color: #ffffff;
    border: 0;
    padding: 10px;
    font-size: 1.2em;
    border-radius: 4px;
  }
    .mobile-menu-trigger1 {
    border: 0;
    padding: 10px;
    font-size: 1.2em;
  }

  .mobile-menu-header {
    order: -1;
    background: grey;
    a {
      padding: 20px 25px;
      color: #ffffff;
      visibility: visible;
    }
  }
  .hide2 {
    display: none !important;
  }
  .menu-bar {
    flex-direction: column;
    position: fixed;
    top: 0;
    left: -100%;
    height: 100vh;
    width: 350px;
    z-index: 11111;
    max-width: 350px;
    max-width: 90%;
    overflow-x: hidden;
    transition: left .3s;
    box-shadow: 1px 0px 2px 0px rgba(0,0,0,0.25);
    > li {
      > [aria-haspopup="true"] {
        ~ ul {
          display: flex;
          flex-direction: column;
          background: #ffffff;
          position: absolute;
          left: 100%;
          top: 0;
          max-height: 100vh;
          width: 100%;
          transition: left .3s;
          // Second level
          > li {
            > [aria-haspopup="true"] {
              font-size: 1.2em;
              ~ ul {
                a {
                  padding-left: 40px;
                }
                // Third level
                > li {
                  > [aria-haspopup="true"] {
                    ~ ul {
                      a {
                        padding-left: 80px;
                      }
                    }
                  }
                }
              }
            }
          }
          [aria-haspopup="true"] {
            color: #2a2a2a;
            &:after {
              content: "+";
              background: none;
              font-size: 1em;
              font-weight: normal;
              height: 20px;
              line-height: 1;
            }
            ~ ul {
              max-height: 0px;
              transform-origin: top;
              transform: scaleY(0);
              transition: max-height .1s;
            }
          }
        }
      }
    }
  }
 

  .mega-menu-content {
    padding: 20px 25px;
  }

  .mobile-menu-back-item {
    order: -1;
    a {
      background: tint(grey, 70%);
      color: #2a2a2a;
      max-height: "calc(1.4em + 40px)";
      margin-top: "calc(0px - (1.4em + 40px))";
      pointer-events: none;
      &:before {
        content: "";
        width: 14px;
        height: 12px;
        background-image: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/1397521/arrowLeft.svg#default');
        background-size: 14px;
        margin-right: 10px;
        display: inline-block;
      
      }

      
      
    }
  }

  // ------------------------ ALL DEVICES

  .mobile-menu-trigger {
    // FUNCTIONALITY: Open mobile menu
    &:focus {
      ~ ul {
        left: 0;
      }
    }
  }

  .menu-bar {
    // FUNCTIONALITY: Keep menu open
    &:hover, &:focus-within {
      left: 0;
    }
    > li {
      > [aria-haspopup="true"] {
        // FUNCTIONALITY: Open mega menu
        &:focus {
          ~ ul {
            left: 0;
          }
        }
        ~ ul {
          // STYLING: Back button offset
          margin-top: "calc(1.4em + 40px)";

          // FUNCTIONALITY: Keep mega menu open
          &:hover, &:focus-within {
            left: 0;
          }
          // FUNCTIONALITY: Open dropdowns
          [aria-haspopup="true"] {
            &:hover {
              ~ ul {
                max-height: 500px;
                animation: dropdown .3s forwards;
              }
            }
          }
          // FUNCTIONALITY: Keep dropdowns open
          li {
            &:focus-within {
              > [aria-haspopup="true"] {
                ~ ul {
                  max-height: 500px;
                  transform: scaleY(1);
                }
              }
            }
          }

        }
      }
      // FUNCTIONALITY: Prevent clicks on link behind back button
      &:focus-within ~ .mobile-menu-header a {
        visibility: hidden;
      }
    }
  }

  // ------------------------ TOUCH DEVICES

  @media  all and (hover: none) {

    // FUNCTIONALITY: Open mobile menu 
    .mobile-menu-trigger {
      &:hover {
        ~ ul {
          left: 0;
        }
      }
    }
    

    // FUNCTIONALITY: Open mega menu
    .menu-bar {
      > li {
        > [aria-haspopup="true"] {
          &:hover {
            ~ ul {
              left: 0;
            }
          }
          ~ ul {
            &:hover {
              left: 0;
            }
            // FUNCTIONALITY: Open dropdowns
            [aria-haspopup="true"] {
              &:hover {
                ~ ul {
                  max-height: 500px;
                  animation: dropdown .3s forwards;
                }
              }
              ~ ul {
                &:hover {
                  max-height: 500px;
                  transform: scaleY(1);
                }
              }
            }
          }
        }
        &:hover ~ .mobile-menu-header {
          a {
            visibility: hidden;
          }
        }
      }
    }
  }
}


// ------------------ ANIMATIONS

@keyframes dropdown {
  0% {
    opacity: 0;
    transform: scaleY(0);
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scaleY(1);
  }
}

@keyframes flyout {
  0% {
    opacity: 0;
    transform: scaleX(0);
  }
  100% {
    opacity: 1;
    transform: scaleX(1);
  }
}
  
  @media only screen and (max-width: 950px) {
      .hideDivMb{
          display: none;
        }
  }
  @media only screen and (min-width: 1023px) and (max-width: 1024px) {
    // .hideDivMb{
    //   display: none;
    // }
    .ipad-pro-margin {
      margin-right: -266px;
    }
    
    	.pd_btn {
		display: flex;
		justify-content: right;
		margin-left: 3px !important;
		padding-bottom: 22px !important;
	}
      .mega-menu {
    // LAYOUT: Mega menu
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 262px !important;
    // FUNCTIONALITY: Keep mega menu open
    &:hover {
      display: none;
    } 

    a {
      &:hover {
        background: tint(#177E89, 85%);
        color: darken(#177E89, 5%);
        
      }

    }
    }
  .menu-link {
    padding: 20px 25px;
    background: #ffffff;
    color: #177E89;
    transition: background .2s, color .2s;
    position: relative;
    z-index: 1;
    &[aria-haspopup="true"] {
      padding-right: 32px;
      &:after {
        content: "";
        background-image: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/1397521/arrowRight.svg#accent');
        background-size: 14px;
        width: 14px;
        height: 14px;
        font-size:12px;
        position: absolute;
        // right: 10px;
        top:50%;
        transform: translateY(-50%);
      }
    }
  }    
}
@media only screen and (max-width: 768px) {
  .logomobile {
    width:100px;
    margin-left:15px;
    margin-bottom:-40px
  }
}
@media only screen and (min-width: 768px) and (max-width: 950px) {
.hidelogodesktop{
      display: none;
    }
    .login .btn.login-btn {
		font-size: 12px;
		font-weight: 900;
		color: #0db8b6;
		text-transform: uppercase;
		border: 2px solid #0db8b6;
		border-radius: 50px;
		padding: 10px 30px;
		margin-top: 0px;
		margin-bottom: 10px;
		z-index: 1111111; 
		position: absolute;
		bottom: 0px;
	}
}

  
 
   
@media only screen and (min-width: 950px) {
  .hidelogodesktop{
    display: none;
  }
}    
</style>

